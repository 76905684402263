import React, { useState } from 'react';
// import { styled } from "@stitches/react";
import { styled } from '../stitches.config';
import * as LabelPrimitive from '@radix-ui/react-label';
import { red } from '@radix-ui/colors';
import {
  ContainerItem,
  ContainerItemNote,
  EmptyDiv,
  QuickTipClear,
  StepSubtitle,
  TextLink,
} from './common';
import { ContinueButton, CreateSmallButton, DateButton } from './buttons';
import { call } from '../utils/SelfServe/contracts';
import { ToastTransaction } from './toasts';
import ReactDatePicker from 'react-datepicker';
import { licenseNameAtom, licenseUriAtom } from './atoms';
import { useAtom } from 'jotai';

export const Label = styled(LabelPrimitive.Root, {
  fontSize: 18,
  userSelect: 'none',
  maxWidth: 'fit-content',
  // lineHeight: "48px",
  margin: '24px 0',
});

const Flex = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const Input = styled('input', {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 10,
  padding: 16,
  fontSize: 16,
  border: '1px solid #dcdde2',
  fontFamily: 'inherit',
  '&:focus': { boxShadow: `0 0 0 1px #0055E1` },
});

const Select = styled('select', {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 10,
  padding: 16,
  fontSize: 16,
  border: '1px solid #dcdde2',
  fontFamily: 'inherit',
  '&:focus': { boxShadow: `0 0 0 1px #0055E1` },
});

const QuickInput = styled('input', {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 10,
  padding: 16,
  fontSize: 16,
  border: '1px solid #dcdde2',
  fontFamily: 'inherit',
  '&:focus': { boxShadow: `0 0 0 1px #0055E1` },
});

export const Note = styled('div', {
  marginBottom: 20,
});

const TextArea = styled('textarea', {
  background: '$background',
  color: '$text',
  width: '100%',
  height: '112px',
  resize: 'none',
  boxSizing: 'border-box',
  borderRadius: 10,
  padding: 16,
  border: '1px solid #dcdde2',
  fontFamily: 'inherit',
  fontSize: 16,
  '&:focus': { boxShadow: `0 0 0 1px #0055E1` },
});

const Error = styled('span', {
  lineHeight: '28px',
  marginLeft: 10,
  color: red.red9,
  fontSize: 'small',
});

export const LabelInput = (props: {
  placeholder: string;
  label: string;
  id: string;
  type: string;
  value?: string;
  onChange?: any;
  onBlur?: any;
  error?: string;
  note?: string;
  disabled?: boolean;
}) => (
  <Flex css={{ flexWrap: 'wrap' }}>
    <Label htmlFor={props.id}>
      {props.label}
      {props.error && <Error>{props.error}</Error>}
    </Label>
    {props.note ? <Note>{props.note}</Note> : null}
    <Input
      autoComplete="off"
      type={props.type}
      id={props.id}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange ? (e) => props.onChange(e) : undefined}
      onBlur={props.onBlur ? (e) => props.onBlur(e) : undefined}
      disabled={props.disabled || false}
      css={props.disabled ? { backgroundColor: '$border' } : {}}
      onWheel={(e) => e.currentTarget.blur()}
    />
  </Flex>
);

export const LabelTextArea = (props: {
  placeholder: string;
  label: string;
  id: string;
  value: string;
  onChange?: any;
  onBlur?: any;
  error?: string;
  note?: string;
}) => (
  <Flex css={{ flexWrap: 'wrap' }}>
    <Label htmlFor={props.id}>
      {props.label}
      {props.error && <Error>{props.error}</Error>}
    </Label>
    {props.note ? <Note>{props.note}</Note> : null}
    <TextArea
      id={props.id}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange ? (e) => props.onChange(e) : undefined}
      onBlur={props.onBlur ? (e) => props.onBlur(e) : undefined}
    />
  </Flex>
);

export const LabelQuickAction = (props: {
  placeholder?: string;
  label?: string;
  id?: string;
  type?: string;
  note?: string;
  functionName: string;
  contractAddress: string;
  contractName: string;
  access: string;
  args: { name: string; type: any }[];
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  function handleCancel() {
    // setUrl(
    //   'https://explorer.stacks.co/txid/0x2aa2c1c0866353f5587185c5dc4acaf8ecb6237815698975c8fe06ec8e5fc3a4?chain=mainnet',
    // );
  }

  function handleFinish(res: any, url?: string) {
    console.log(res);
    setError(res);
    if (url) {
      setUrl(url);
    }
  }

  function handleToastClose() {
    setUrl('');
  }

  function handleContractCall() {
    const functionArgs = [];
    for (let i = 0; i < props.args.length; i++) {
      let type = '';
      if (typeof props.args[i].type === 'object') {
        type = Object.keys(props.args[i].type)[0];
      } else {
        type = props.args[i].type;
      }
      let value = currentValue;
      console.log(props.functionName);
      if (props.functionName === 'set-price') {
        value = (parseInt(value) * 1e6).toString();
      } else if (props.functionName === 'set-royalty-percent') {
        value = (parseInt(value) * 1e2).toString();
      }
      const arg = { value, type };
      functionArgs.push(arg);
    }

    // if (props.access === 'public') {
    //   setProgress('Confirm your contract call in the dialog window...');
    // } else if (props.access === 'read_only') {
    //   setProgress('Fetching result...');
    // }

    call(
      props.contractAddress,
      props.contractName,
      props.functionName,
      props.access,
      functionArgs,
      handleFinish,
      handleCancel,
    );
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCurrentValue(e.target.value);
  }

  function handleInputBlur() {}

  return (
    <QuickTipClear css={{ marginBottom: '30px', borderRadius: '12px' }}>
      <Flex>
        {props.label ? (
          <Label htmlFor={props.id} css={{ marginTop: 0, fontWeight: 'bold' }}>
            {props.label}
            {error && <Error>{error}</Error>}
          </Label>
        ) : null}
        {props.note ? <ContainerItemNote>{props.note}</ContainerItemNote> : null}

        {props.placeholder && props.type && props.id ? (
          <ContainerItemNote>
            <Input
              css={{ width: '90%' }}
              autoComplete="off"
              type={props.type}
              id={props.id}
              placeholder={props.placeholder}
              value={currentValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
              onBlur={() => handleInputBlur()}
            />
          </ContainerItemNote>
        ) : null}

        {props.functionName ? (
          <CreateSmallButton text={props.functionName} onClick={() => handleContractCall()} />
        ) : null}

        {url ? <ToastTransaction onClose={() => handleToastClose()} url={url} /> : null}
      </Flex>
    </QuickTipClear>
  );
};

export const LabelUpdateRoyalty = (props: {
  placeholder?: string;
  label?: string;
  id?: string;
  type?: string;
  note?: string;
  functionName: string;
  contractAddress: string;
  contractName: string;
  access: string;
  args: { name: string; type: any }[];
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  function handleCancel() {
    // setUrl(
    //   'https://explorer.stacks.co/txid/0x2aa2c1c0866353f5587185c5dc4acaf8ecb6237815698975c8fe06ec8e5fc3a4?chain=mainnet',
    // );
  }

  function handleFinish(res: any, url?: string) {
    console.log(res);
    setError(res);
    if (url) {
      setUrl(url);
    }
  }

  function handleToastClose() {
    setUrl('');
  }

  function handleContractCall() {
    const functionArgs = [];
    for (let i = 0; i < props.args.length; i++) {
      let type = '';
      if (typeof props.args[i].type === 'object') {
        type = Object.keys(props.args[i].type)[0];
      } else {
        type = props.args[i].type;
      }
      let value = currentValue;
      console.log(props.functionName);
      if (props.functionName === 'set-price') {
        value = (parseInt(value) * 1e6).toString();
      } else if (props.functionName === 'set-royalty-percent') {
        value = (parseInt(value) * 1e2).toString();
      }
      const arg = { value, type };
      functionArgs.push(arg);
    }

    // if (props.access === 'public') {
    //   setProgress('Confirm your contract call in the dialog window...');
    // } else if (props.access === 'read_only') {
    //   setProgress('Fetching result...');
    // }

    call(
      props.contractAddress,
      props.contractName,
      props.functionName,
      props.access,
      functionArgs,
      handleFinish,
      handleCancel,
    );
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    if (isNaN(parseInt(e.target.value)) || parseInt(e.target.value) > 20) {
      setError('Royalty must be an integer between 0-10 percent');
    }
    setCurrentValue(e.target.value);
  }

  function handleInputBlur() {}

  return (
    <QuickTipClear css={{ marginBottom: '30px', borderRadius: '12px' }}>
      <Flex>
        {props.label ? (
          <Label htmlFor={props.id} css={{ marginTop: 0, fontWeight: 'bold' }}>
            {props.label}
          </Label>
        ) : null}
        {props.note ? <ContainerItemNote>{props.note}</ContainerItemNote> : null}

        {props.placeholder && props.type && props.id ? (
          <ContainerItemNote>
            <Input
              css={{ width: '90%' }}
              autoComplete="off"
              type={props.type}
              id={props.id}
              placeholder={props.placeholder}
              value={currentValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
              onBlur={() => handleInputBlur()}
            />
          </ContainerItemNote>
        ) : null}

        {props.functionName ? (
          <ContinueButton
            disabled={error !== ''}
            text={props.functionName}
            onClick={() => handleContractCall()}
          />
        ) : null}

        {error && currentValue && (
          <EmptyDiv css={{ color: 'Red', fontSize: 14, marginTop: 20 }}>{error}</EmptyDiv>
        )}

        {url ? <ToastTransaction onClose={() => handleToastClose()} url={url} /> : null}
      </Flex>
    </QuickTipClear>
  );
};

export const LabelQuickDownload = (props: {
  placeholder?: string;
  label?: string;
  id?: string;
  type?: string;
  note?: string;
  onClick: any;
  buttonText: string;
}) => {
  return (
    <QuickTipClear css={{ marginBottom: '30px', borderRadius: '12px' }}>
      <Flex>
        {props.label ? (
          <Label htmlFor={props.id} css={{ marginTop: 0, fontWeight: 'bold' }}>
            {props.label}
          </Label>
        ) : null}
        {props.note ? <ContainerItemNote>{props.note}</ContainerItemNote> : null}
        <CreateSmallButton text={props.buttonText} onClick={() => props.onClick()} />
      </Flex>
    </QuickTipClear>
  );
};

export const LabelDatePicker = (props: {
  label: string;
  id: string;
  onChange?: any;
  onBlur?: any;
  error?: string;
  note?: string;
  customInput?: boolean;
}) => {
  const [date, setDate] = useState(new Date());

  function handleDateChange(date: Date) {
    setDate(date);
    if (props.onChange) {
      props.onChange(date);
    }
  }

  return (
    <Flex css={{ flexWrap: 'wrap' }}>
      <Label htmlFor={props.id}>
        {props.label}
        {props.error && <Error>{props.error}</Error>}
      </Label>
      {props.note ? <Note>{props.note}</Note> : null}
      <ReactDatePicker
        selected={date}
        onChange={(date: Date) => handleDateChange(date)}
        showTimeSelect
        startDate={date}
        dateFormat={'MMM d, yyyy h:mm aa'}
        placeholderText="Start date"
        customInput={
          props.customInput ? (
            <DateButton>
              {date.toLocaleDateString()} {date.toLocaleTimeString()}
            </DateButton>
          ) : null
        }
        // inline
      />
    </Flex>
  );
};

export const LabelLicenses = (props: {
  label: string;
  id: string;
  onChange?: any;
  onBlur?: any;
  error?: string;
  note?: string;
  customInput?: boolean;
}) => {
  const [selectedValue, setSelectedValue] = useState(0);
  const [licenseUri, setLicenseUri] = useAtom(licenseUriAtom);
  const [licenseName, setLicenseName] = useAtom(licenseNameAtom);

  function handleOptionChange() {
    const sel: any = document.getElementById('options');
    const option = sel.options[sel.selectedIndex];
    // const text = option.text;
    const value = parseInt(option.value);
    setSelectedValue(value);
    if (value === 0) {
      setLicenseUri('');
      setLicenseName('');
    } else {
      setLicenseUri(licenseData[value - 1].link);
      setLicenseName(licenseData[value - 1].name);
    }
  }

  const licenseData = [
    {
      name: 'PUBLIC',
      title: 'CC0',
      description:
        'All copyrights are waived under the terms of CC0 1.0 Universal developed by Creative Commons.',
      link: 'https://arweave.net/zmc1WTspIhFyVY82bwfAIcIExLFH5lUcHHUN0wXg4W8/0',
    },
    {
      name: 'EXCLUSIVE',
      title: 'Exclusive Commercial Rights with No Creator Retention',
      description:
        'Full exclusive commercial rights granted, with no hate speech termination. Creator does not retain any exploitation rights.',
      link: 'https://arweave.net/zmc1WTspIhFyVY82bwfAIcIExLFH5lUcHHUN0wXg4W8/1',
    },
    {
      name: 'COMMERCIAL',
      title: 'Non-Exclusive Commercial Rights',
      description:
        'Full non-exclusive commercial rights granted, with no hate speech termination. Creator retains exploitation rights.',
      link: 'https://arweave.net/zmc1WTspIhFyVY82bwfAIcIExLFH5lUcHHUN0wXg4W8/2',
    },
    {
      name: 'COMMERCIAL-NO-HATE',
      title: 'Non-Exclusive Commercial Rights with Creator Retention & Hate Speech Termination',
      description:
        'Full non-exclusive commercial rights granted, with hate speech termination. Creator retains exploitation rights.',
      link: 'https://arweave.net/zmc1WTspIhFyVY82bwfAIcIExLFH5lUcHHUN0wXg4W8/3',
    },
    {
      name: 'PERSONAL',
      title: 'Personal License',
      description: 'Personal rights granted, without hate speech termination.',
      link: 'https://arweave.net/zmc1WTspIhFyVY82bwfAIcIExLFH5lUcHHUN0wXg4W8/4',
    },
    {
      name: 'PERSONAL-NO-HATE',
      title: 'Personal License with Hate Speech Termination',
      description: 'Personal rights granted, with hate speech termination.',
      link: 'https://arweave.net/zmc1WTspIhFyVY82bwfAIcIExLFH5lUcHHUN0wXg4W8/5',
    },
  ];

  return (
    <Flex css={{ flexWrap: 'wrap' }}>
      <Label htmlFor={props.id}>
        {props.label}
        {props.error && <Error>{props.error}</Error>}
      </Label>
      {props.note ? <Note>{props.note}</Note> : null}
      <Note>
        Read more about the available licenses{' '}
        <TextLink
          href="https://a16zcrypto.com/introducing-nft-licenses/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </TextLink>
        .
      </Note>
      <Select name="options" id="options" onChange={() => handleOptionChange()}>
        <option value={0}>--- select a license ---</option>
        <option value={1}>{licenseData[0].name}</option>
        <option value={2}>{licenseData[1].name}</option>
        <option value={3}>{licenseData[2].name}</option>
        <option value={4}>{licenseData[3].name}</option>
        <option value={5}>{licenseData[4].name}</option>
        <option value={6}>{licenseData[5].name}</option>
      </Select>
      {selectedValue !== 0 ? (
        <ContainerItem>
          <h5>{licenseData[selectedValue - 1].title}</h5>
          <code>
            {licenseData[selectedValue - 1].description}{' '}
            <TextLink
              href={licenseData[selectedValue - 1].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              View license.
            </TextLink>
          </code>
        </ContainerItem>
      ) : null}
    </Flex>
  );
};

import { openContractDeploy, openContractCall } from '@stacks/connect';
import { StacksTestnet, StacksMainnet, NetworkConfig } from '@stacks/network';
import {
  PostConditionMode,
  makeStandardSTXPostCondition,
  createAssetInfo,
  makeStandardFungiblePostCondition,
  FungibleConditionCode,
  callReadOnlyFunction,
  cvToJSON,
  standardPrincipalCV,
  contractPrincipalCV,
  uintCV,
  stringAsciiCV,
  ClarityType,
  makeContractSTXPostCondition,
  someCV,
  AnchorMode,
  tupleCV,
  makeStandardNonFungiblePostCondition,
  makeContractNonFungiblePostCondition,
  NonFungibleConditionCode,
  listCV,
  noneCV,
} from '@stacks/transactions';
import { defaultTemplate } from './templates/default';
import { editionsTemplate } from './templates/editions';
import {
  mintDefault,
  mintMintpass,
  mintpassExtras,
  transferDefault,
  transferNonCust,
  nonCustExtras,
  letNonCust,
  mintNonCust,
  altDefault,
  altMintpass,
  airdrops,
  reveal,
  getLicense,
} from './templates/components';
import BigNum from 'bn.js';
import { auctionTemplate } from './templates/auction';
import { personalTemplate } from '../Studio/templates/personal';
import { isValidAddress, isValidContractPrincipal } from './validation';
import { Configuration } from '@stacks/blockchain-api-client';
const Drop_ABI = require('../../../node_modules/@zoralabs/nft-drop-contracts/dist/artifacts/ERC721Drop.sol/ERC721Drop.json');
const FeeManager_ABI = require('../../../node_modules/@zoralabs/nft-drop-contracts/dist/artifacts/ZoraFeeManager.sol/ZoraFeeManager.json');

export const ethDeployAddressGoerli = '0x1045b195aAb30B450715285aF26282f19d71a880';
export const ethDeployAddressMainnet = '0x3AC94cFAD385e1c47702280E87738dA94a1fb434';
export const ethNetwork = 'goerli';
// export const ethNetwork = 'mainnet'
export const etherscanUrlMainnet = 'https://etherscan.io';
export const etherscanUrlGeorli = 'https://goerli.etherscan.io';
export const dropAbi = Drop_ABI.abi;
export const feeManagerAbi = FeeManager_ABI.abi;

// TOGGLE THIS TO GO FROM TESTNET ON LOCALHOST TO MAINNET ON SERVER
const isMainnet = true;
// const isMainnet = false;

let network = new StacksTestnet();
let networkString = 'testnet';
let commAddress = 'STK9FCNG823TEH0JD64RKQXMQMAZ0K69TCP9WXMD';
let basePath = 'https://stacks-node-api.testnet.stacks.co';
if (isMainnet) {
  basePath = 'https://api.gamma.io';
  // basePath = 'https://api.stacks.org';
  // basePath = 'https://stacks-node-api.mainnet.stacks.co';

  const config: NetworkConfig = { url: basePath };
  network = new StacksMainnet(config);
  // network = new StacksMainnet();
  networkString = 'mainnet';
  commAddress = 'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S';
}

const pass = 'asurpriserofl';

// const auctionContractPrincipal =
//   "SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA.aux5";
// const auctionContractAddress = "SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA";
// const auctionContractName = "aux5";

const auctionContractPrincipal = 'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA.stxnft-auctions-v1';
const auctionContractAddress = 'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA';
const auctionContractName = 'stxnft-auctions-v1';

const auctionAddresses = [
  'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA', // me
  'SP3BRRCHKMPBR60V8ES9J5YF40VXWMABWXK4SEB9G', // Arno
  'SP3M05ETW09E98NNFMFHT1WND3ZRX9DV31TFC6DFW', // Boozy
  'SP1GR33848GSTMFR955Z77DAB835XYE9FZG19Y7NX', // Levi
  'SP27PMQWPBDV1XK9HK259XZNHW8B6XZJJXMNQCV6Y', // Longstreet
  'SPXGFH9JTKPF2TQZJ2AH7NSMMMXJ72VMGH8PR654', // Dyle
  'SP35K3WCA9GCJV2XC7X021MR2D9D2PKF855CVCKB0', // Grace
  'SPVVB6WRVE757VKEB2T0X5ZY4DMFJAX248XXQHHW', // Cailean
  'SP3ZBTBZGT7Z48EXYQGYJXYMT6JHFC3CH79HBGZ71', // Luxury Goods
  'SP3EB7YHV5NERS2HMCGSGKNMN0BEH52W9H7YE4T9C', // Mendaxx
  'SP39359DFWA9S6AFWRWJHQXRFHTKBY6Q4PC9JX3YW', // BrightDreams.io
  'SP3ZMEFW7VH796ZQAH1JMAJT4WC4VPEZZFB6W5CAD', // Alexander.btc
  'SP26GZCVY8FYHNZ6C73W68TCFJHS8F8C9E772XX7X', // nicole.btc
  'SP2VG7S0R4Z8PYNYCAQ04HCBX1MH75VT11VXCWQ6G', // JakeBlockchain
  'SPP3HM2E4JXGT26G1QRWQ2YTR5WT040S5NKXZYFC', // rapha.btc
  'SP3J04SSW6NXDGVSJC0MCPZRFG2XEGK0QXSFZ7P2X', // wren.btc
  'SPQS1J3X9FJ6N4E9K2MW81W5DNBSCC8ZPHR6K2YA', // carole
  'SP1NJ36TS9JKXB1645Y42Q2YY3V4HV23JPAQHSF0N', // aeroswaner
  'SP1QFJWPZA65SAACG0WFY92NMNSNJ3FBN30A8NBPQ', // ubayd
  'SP38F2NEWKCN47Y9QV09MHKFY5R67QWVDYV7Y53K1', // satriayuwan
  'SP13MG2XZDFEY1J7N8FHXST40YNVPF6795PFGMWF4', // gasparian
  'SP1XPG9QFX5M95G36SGN9R8YJ4KJ0JB7ZXNH892N6', // Jamil ref
  'SP3QK75VP0Y64SAJNKTNH5WBBR798C8XAR8T4PJ6W', // Jamil ref
  'SP3K22XKPT9WJFCE957J94J6XXVZHP7747YNPDTFD', // Brett
  'SPP5CDYG2N4XYRCFTQWQKRH3446FQFMS7M6GGTEY', // @eyelectric_
  'SP22W7TM6NG3PJ2XVVND2E06D50K3DDNREBTKGFD3', // another one for brett
  'SP2T2PGPBS062RZ8KPB2JF9HW1MZT0BV70PGBQ2KJ', // brett ref
  'SP1V19KW8DVQ5D8YPBVHBF9NZXWMC0Q4FGG7S9NRY', // jamil ref
  'SP1JETK8M45HEY2N932QYDPXB6EJHWHX3AYF07NKX', // aslanksahin
  'SP2QDMH88MEZ8FFAYHW4B0BTXJRTHX8XBD54FE7HJ', // Nick
  'SP1SZ96GC74MJPX8D9BRMK1Y6Q9JG266TN2M04AJK', // Brett ref
  'SP3SC5PSKQM9ABTYPNYDV1J7SBGHA08VRW1DKTJK6', // Brett ref
  'SP2R3CHRAP1HE4M64X1NZXHZT41JG3XGNHJW4HX2W', // Jack
  'SP2N65XG5NHHZQB50X119YMZ018AP0FCSXDJPNE05', // Tyler Foust
  'SPECW310NYWYZQXJMG734PQKPMAVXYY3YGWZ9YJB', // Prosper
];

async function deployPersonalContract(
  contractName: string,
  artistAddress: string,
  licenseUri: string,
  licenseName: string,
  onFinish: any,
  onCancel: any,
  uris?: string[],
) {
  let contract = personalTemplate;

  let raw = contract.replaceAll('$CONTRACTNAME', contractName);
  raw = raw.replaceAll('$ARTISTADDRESS', artistAddress);

  let license = getLicense;
  if (licenseUri && licenseName) {
    license = license.replaceAll('$LICENSEURI', licenseUri);
    license = license.replaceAll('$LICENSENAME', licenseName);
  } else {
    license = license.replaceAll('$LICENSEURI', '');
    license = license.replaceAll('$LICENSENAME', '');
  }
  raw = raw.replaceAll('$LICENSE', license);

  let nftTrait = "(impl-trait 'SP2PABAF9FTAJYNFZH93XENAJ8FVY99RRM50D2JG9.nft-trait.nft-trait)";
  if (!network.isMainnet()) {
    nftTrait = "(impl-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.nft-trait.nft-trait)";
  }
  raw = raw.replaceAll('$NFTTRAIT', nftTrait);

  let commissionTrait =
    "(use-trait commission-trait 'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.commission-trait.commission)";
  if (!network.isMainnet()) {
    commissionTrait =
      "(use-trait commission-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.commission-trait.commission)";
  }
  raw = raw.replaceAll('$COMMISSIONTRAIT', commissionTrait);

  if (uris) {
    console.log('TODO: Add claim function with list of uris');

    // TEST THIS PART IN CLARINET TO SEE HOW IT WOULD BE CALLED
    // (list "QmPMsTPVp8JrJLeMS2xt7oPjxbnkV7SGu9uwaXwj7TXn75/json/1.json" "QmPMsTPVp8JrJLeMS2xt7oPjxbnkV7SGu9uwaXwj7TXn75/json/2.json" "QmPMsTPVp8JrJLeMS2xt7oPjxbnkV7SGu9uwaXwj7TXn75/json/3.json" "QmPMsTPVp8JrJLeMS2xt7oPjxbnkV7SGu9uwaXwj7TXn75/json/4.json" "QmPMsTPVp8JrJLeMS2xt7oPjxbnkV7SGu9uwaXwj7TXn75/json/5.json")

    let rows = '';
    for (let i = 0; i < uris.length; i++) {
      const uri = uris[i];
      let row = `(try! (nft-mint? ${contractName} u${i + 1} '${artistAddress}))\n`;
      row += `(map-set token-count '${artistAddress} (+ (get-balance '${artistAddress}) u1))\n`;
      row += `(map-set cids u${i + 1} "${uri}")\n`;
      rows += row;
    }
    console.log(rows);
    rows += `(var-set last-id u${uris.length})`;
    raw = raw.replaceAll('$MINTS', rows);
    // let rows = '';
    // let row = "(try! (nft-mint? $CONTRACTNAME (+ (var-get last-id) u$ID) '$ADDRESS))\n";
    // row += "(map-set token-count '$ADDRESS (+ (get-balance '$ADDRESS) u1))\n";
    // row += '(map-set cids next-id hash)';

    // let rows = '';
    // let row = "(try! (nft-mint? $CONTRACTNAME (+ (var-get last-id) u$ID) '$ADDRESS))\n";
    // row += "(map-set token-count '$ADDRESS (+ (get-balance '$ADDRESS) u1))\n";
    // for (let i = 0; i < collectionSize; i++) {
    //   const airdropRow = row
    //     .replace('$ID', `${i}`)
    //     .replaceAll('$ADDRESS', artistAddress)
    //     .replace('$CONTRACTNAME', contractName);
    //   rows += airdropRow;
    // }
    // rows += `(var-set last-id u${collectionSize + 1})`;
    // raw = raw.replace('$AIRDROP', rows);
  } else {
    raw = raw.replaceAll('$MINTS', '');
  }

  const options: any = {
    contractName: contractName,
    codeBody: raw,
    network: network,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }

      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${txid}?chain=${networkString}`;
      console.log('View transaction in explorer: ', explorerTransactionUrl);
      onFinish(explorerTransactionUrl, '0x' + txid);
    },
    onCancel: onCancel,
    postConditionMode: PostConditionMode.Deny,
  };

  openContractDeploy(options);
}

async function deployAuctionContract(
  contractName: string,
  artistAddress: string,
  metaHash: string,
  collectionSize: number,
  onFinish: any,
  onCancel: any,
) {
  let contract = auctionTemplate;

  let raw = contract.replaceAll('$CONTRACTNAME', contractName);
  raw = raw.replaceAll('$ARTISTADDRESS', artistAddress);
  raw = raw.replaceAll('$METAHASH', metaHash);
  raw = raw.replaceAll('$COMMADDRESS', commAddress);

  let nftTrait = "(impl-trait 'SP2PABAF9FTAJYNFZH93XENAJ8FVY99RRM50D2JG9.nft-trait.nft-trait)";
  if (!network.isMainnet()) {
    nftTrait = "(impl-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.nft-trait.nft-trait)";
  }
  raw = raw.replaceAll('$NFTTRAIT', nftTrait);

  let commissionTrait =
    "(use-trait commission-trait 'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.commission-trait.commission)";
  if (!network.isMainnet()) {
    commissionTrait =
      "(use-trait commission-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.commission-trait.commission)";
  }
  raw = raw.replaceAll('$COMMISSIONTRAIT', commissionTrait);

  let rows = '';
  let row = "(try! (nft-mint? $CONTRACTNAME (+ (var-get last-id) u$ID) '$ADDRESS))\n";
  row += "(map-set token-count '$ADDRESS (+ (get-balance '$ADDRESS) u1))\n";
  for (let i = 0; i < collectionSize; i++) {
    const airdropRow = row
      .replace('$ID', `${i}`)
      .replaceAll('$ADDRESS', artistAddress)
      .replace('$CONTRACTNAME', contractName);
    rows += airdropRow;
  }
  rows += `(var-set last-id u${collectionSize + 1})`;
  raw = raw.replace('$AIRDROP', rows);

  const options: any = {
    contractName: contractName,
    codeBody: raw,
    network: network,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }

      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${txid}?chain=${networkString}`;
      console.log('View transaction in explorer: ', explorerTransactionUrl);
      onFinish(explorerTransactionUrl);
    },
    onCancel: onCancel,
    postConditionMode: PostConditionMode.Deny,
  };

  openContractDeploy(options);
}

async function deployEditionsContract(
  contractName: string,
  artistAddress: string,
  price: string,
  mintLimit: string,
  jsonHash: string,
  mintpass: any[],
  airdrop: any[],
  claimOptions: Array<number>,
  fungibleOptions: Array<any>,
  fungibleSelected: Array<any>,
  useReveal: boolean,
  usePause: boolean,
  mintLimitPerUser: string,
  licenseUri: string,
  licenseName: string,
  onFinish: any,
  onCancel: any,
) {
  let contract = editionsTemplate;

  let mlpu = parseInt(mintLimitPerUser);
  if (isNaN(mlpu)) {
    mlpu = 0;
  }

  const parsed = parseFloat(price);
  const mstx = Math.round(parsed * 1e6).toString();

  let raw = contract.replaceAll('$CONTRACTNAME', contractName);
  raw = raw.replaceAll('$ARTISTADDRESS', artistAddress);
  raw = raw.replaceAll('$PRICE', mstx);
  raw = raw.replaceAll('$MINTLIMIT', mintLimit || '0');
  raw = raw.replaceAll('$METAHASH', jsonHash);
  raw = raw.replaceAll('$COMMADDRESS', commAddress);
  raw = raw.replaceAll('$PAUSED', usePause.toString());
  raw = raw.replaceAll('$MINTCAP', mlpu.toString());

  let license = getLicense;
  if (licenseUri && licenseName) {
    license = license.replaceAll('$LICENSEURI', licenseUri);
    license = license.replaceAll('$LICENSENAME', licenseName);
  } else {
    license = license.replaceAll('$LICENSEURI', '');
    license = license.replaceAll('$LICENSENAME', '');
  }
  raw = raw.replaceAll('$LICENSE', license);

  let nftTrait = "(impl-trait 'SP2PABAF9FTAJYNFZH93XENAJ8FVY99RRM50D2JG9.nft-trait.nft-trait)";
  if (!network.isMainnet()) {
    nftTrait = "(impl-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.nft-trait.nft-trait)";
  }
  raw = raw.replaceAll('$NFTTRAIT', nftTrait);

  // set transfer function
  let transfer = transferNonCust;
  transfer = transfer.replaceAll('$CONTRACTNAME', contractName);
  raw = raw.replaceAll('$TRANSFERCOMP', transfer);

  // include non-custodial marketplace functions
  let extras = nonCustExtras;
  extras = extras.replaceAll('$CONTRACTNAME', contractName);
  let commissionTrait =
    "(use-trait commission-trait 'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.commission-trait.commission)";
  if (!network.isMainnet()) {
    commissionTrait =
      "(use-trait commission-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.commission-trait.commission)";
  }
  extras = extras.replaceAll('$COMMISSIONTRAIT', commissionTrait);
  raw = raw.replaceAll('$NONCUSTEXTRAS', extras);

  const claimOptionsMap: any = {
    '1': 'one',
    '2': 'two',
    '3': 'three',
    '4': 'four',
    '5': 'five',
    '6': 'six',
    '7': 'seven',
    '8': 'eight',
    '9': 'nine',
    '10': 'ten',
    '15': 'fifteen',
    '20': 'twenty',
    '25': 'twentyfive',
  };

  // set default claim functions
  if (claimOptions != null && claimOptions.length > 0) {
    let options: string = '';
    for (let i = 0; i < claimOptions.length; i++) {
      if (claimOptions[i] === 1) {
        continue;
      }
      const option: string = claimOptions[i].toString();
      const listText: string = ' true';
      options += `
(define-public (claim-${claimOptionsMap[option]}) (mint (list${listText.repeat(claimOptions[i])})))
`;
    }
    raw = raw.replaceAll('$CLAIMOPTS', options);
  } else {
    raw = raw.replaceAll('$CLAIMOPTS', '');
  }

  // include reveal function
  if (useReveal) {
    raw = raw.replaceAll('$REVEALCOMP', reveal);
  } else {
    raw = raw.replaceAll('$REVEALCOMP', '');
  }

  // define mint function
  if (mintpass && mintpass.length > 0) {
    raw = raw.replaceAll('$MINTCOMP', mintMintpass);
  } else {
    raw = raw.replaceAll('$MINTCOMP', mintDefault);
  }

  // implement mintpass extras
  if (mintpass && mintpass.length > 0) {
    raw = raw.replaceAll('$MINTPASSEXTRAS', mintpassExtras);
  } else {
    raw = raw.replaceAll('$MINTPASSEXTRAS', '');
  }

  if (mintpass && mintpass.length > 0) {
    let row = "(map-set mint-passes 'ADDRESS uAMOUNT)\n";
    for (let i = 0; i < mintpass.length; i++) {
      const element = mintpass[i];
      const mintpassRow = row.replace('ADDRESS', element[0]).replace('AMOUNT', element[1]);
      raw += mintpassRow;
    }
  }

  if (airdrop && airdrop.length > 0) {
    let func = airdrops;
    let rows = '';
    let row = "      (try! (nft-mint? $CONTRACTNAME (+ last-nft-id u$ID) '$ADDRESS))\n";
    row += "      (map-set token-count '$ADDRESS (+ (get-balance '$ADDRESS) u1))\n";
    for (let i = 0; i < airdrop.length; i++) {
      const element = airdrop[i];
      const airdropRow = row
        .replace('$ID', `${i}`)
        .replaceAll('$ADDRESS', element)
        .replace('$CONTRACTNAME', contractName);
      rows += airdropRow;
    }
    func = func.replace('$DROPS', rows);
    func = func.replace('$IDREACHED', airdrop.length.toString());
    raw += func;
  } else {
    raw = raw.replace('$LASTID', '1');
  }

  let alts = '';
  let hasAlt = false;
  for (let i = 0; i < fungibleOptions.length; i++) {
    if (!fungibleSelected[i]['selected']) {
      continue;
    }
    hasAlt = true;
    const fungibleOption = fungibleOptions[i];
    let alt: string = fungibleOption['symbol'];
    alt = alt.toLowerCase();
    let component = altDefault;
    if (mintpass && mintpass.length > 0) {
      component = altMintpass;
    }

    if (claimOptions != null && claimOptions.length > 0) {
      let options: string = '';
      for (let i = 0; i < claimOptions.length; i++) {
        if (claimOptions[i] === 1) {
          continue;
        }
        const option: string = claimOptions[i].toString();
        const listText: string = ' true';
        options += `
(define-public (claim-${claimOptionsMap[option]}-${alt}) (mint-${alt} (list${listText.repeat(
          claimOptions[i],
        )})))
`;
      }
      component = component.replaceAll('$CLAIMOPTSALT', options);
    } else {
      component = component.replaceAll('$CLAIMOPTSALT', '');
    }

    let altTransferArtist = `(try! (contract-call? '${fungibleOption['principal']} transfer total-artist tx-sender (var-get artist-address) (some 0x00)))`;
    let altTransferComm = `(try! (contract-call? '${fungibleOption['principal']} transfer total-commission tx-sender COMM-ADDR (some 0x00)))`;
    if (!network.isMainnet()) {
      altTransferArtist = '(try! (stx-transfer? total-artist tx-sender (var-get artist-address)))';
      altTransferComm = '(try! (stx-transfer? total-commission tx-sender COMM-ADDR))';
    }

    component = component.replaceAll('$ALTTRANSFERARTIST', altTransferArtist);
    component = component.replaceAll('$ALTTRANSFERCOMM', altTransferComm);
    component = component.replaceAll('$PRICEALT', fungibleSelected[i]['price']);
    component = component.replaceAll('$ALT', alt);
    alts += component;
  }

  if (hasAlt) {
    raw = raw.replaceAll('$ALTS', alts);
  } else {
    raw = raw.replaceAll('$ALTS', '');
  }

  let nonCustLet = letNonCust;
  raw = raw.replaceAll('$LETNONCUST', nonCustLet);
  let nonCustMint = mintNonCust;
  raw = raw.replaceAll('$MINTNONCUST', nonCustMint);

  const options: any = {
    contractName: contractName,
    codeBody: raw,
    network: network,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }

      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${txid}?chain=${networkString}`;
      console.log('View transaction in explorer: ', explorerTransactionUrl);
      onFinish(explorerTransactionUrl, '0x' + txid);
    },
    onCancel: onCancel,
    postConditionMode: PostConditionMode.Deny,
  };

  openContractDeploy(options);
}

async function deployPublicContract(
  contractName: string,
  artistAddress: string,
  price: string,
  mintLimit: string,
  metaHash: string,
  mintpass: any[],
  airdrop: any[],
  claimOptions: Array<number>,
  fungibleOptions: Array<any>,
  fungibleSelected: Array<any>,
  useReveal: boolean,
  usePause: boolean,
  mintLimitPerUser: string,
  licenseUri: string,
  licenseName: string,
  onFinish: any,
  onCancel: any,
) {
  let contract = defaultTemplate;

  let mlpu = parseInt(mintLimitPerUser);
  if (isNaN(mlpu)) {
    mlpu = 0;
  }

  const parsed = parseFloat(price);
  const mstx = Math.round(parsed * 1e6).toString();

  let raw = contract.replaceAll('$CONTRACTNAME', contractName);
  raw = raw.replaceAll('$ARTISTADDRESS', artistAddress);
  raw = raw.replaceAll('$PRICE', mstx);
  raw = raw.replaceAll('$MINTLIMIT', mintLimit);
  raw = raw.replaceAll('$METAHASH', metaHash);
  raw = raw.replaceAll('$COMMADDRESS', commAddress);
  raw = raw.replaceAll('$PAUSED', usePause.toString());
  raw = raw.replaceAll('$MINTCAP', mlpu.toString());

  let license = getLicense;
  if (licenseUri && licenseName) {
    license = license.replaceAll('$LICENSEURI', licenseUri);
    license = license.replaceAll('$LICENSENAME', licenseName);
  } else {
    license = license.replaceAll('$LICENSEURI', '');
    license = license.replaceAll('$LICENSENAME', '');
  }
  raw = raw.replaceAll('$LICENSE', license);

  let nftTrait = "(impl-trait 'SP2PABAF9FTAJYNFZH93XENAJ8FVY99RRM50D2JG9.nft-trait.nft-trait)";
  if (!network.isMainnet()) {
    nftTrait = "(impl-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.nft-trait.nft-trait)";
  }
  raw = raw.replaceAll('$NFTTRAIT', nftTrait);

  // set transfer function
  let transfer = transferNonCust;
  transfer = transfer.replaceAll('$CONTRACTNAME', contractName);
  raw = raw.replaceAll('$TRANSFERCOMP', transfer);

  // include non-custodial marketplace functions
  let extras = nonCustExtras;
  extras = extras.replaceAll('$CONTRACTNAME', contractName);
  let commissionTrait =
    "(use-trait commission-trait 'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.commission-trait.commission)";
  if (!network.isMainnet()) {
    commissionTrait =
      "(use-trait commission-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.commission-trait.commission)";
  }
  extras = extras.replaceAll('$COMMISSIONTRAIT', commissionTrait);
  raw = raw.replaceAll('$NONCUSTEXTRAS', extras);

  const claimOptionsMap: any = {
    '1': 'one',
    '2': 'two',
    '3': 'three',
    '4': 'four',
    '5': 'five',
    '6': 'six',
    '7': 'seven',
    '8': 'eight',
    '9': 'nine',
    '10': 'ten',
    '15': 'fifteen',
    '20': 'twenty',
    '25': 'twentyfive',
  };

  // set default claim functions
  if (claimOptions != null && claimOptions.length > 0) {
    let options: string = '';
    for (let i = 0; i < claimOptions.length; i++) {
      if (claimOptions[i] === 1) {
        continue;
      }
      const option: string = claimOptions[i].toString();
      const listText: string = ' true';
      options += `
(define-public (claim-${claimOptionsMap[option]}) (mint (list${listText.repeat(claimOptions[i])})))
`;
    }
    raw = raw.replaceAll('$CLAIMOPTS', options);
  } else {
    raw = raw.replaceAll('$CLAIMOPTS', '');
  }

  // include reveal function
  if (useReveal) {
    raw = raw.replaceAll('$REVEALCOMP', reveal);
  } else {
    raw = raw.replaceAll('$REVEALCOMP', '');
  }

  // define mint function
  if (mintpass && mintpass.length > 0) {
    raw = raw.replaceAll('$MINTCOMP', mintMintpass);
  } else {
    raw = raw.replaceAll('$MINTCOMP', mintDefault);
  }

  // implement mintpass extras
  if (mintpass && mintpass.length > 0) {
    raw = raw.replaceAll('$MINTPASSEXTRAS', mintpassExtras);
  } else {
    raw = raw.replaceAll('$MINTPASSEXTRAS', '');
  }

  if (mintpass && mintpass.length > 0) {
    let row = "(map-set mint-passes 'ADDRESS uAMOUNT)\n";
    for (let i = 0; i < mintpass.length; i++) {
      const element = mintpass[i];
      const mintpassRow = row.replace('ADDRESS', element[0]).replace('AMOUNT', element[1]);
      raw += mintpassRow;
    }
  }

  if (airdrop && airdrop.length > 0) {
    let func = airdrops;
    let rows = '';
    let row = "      (try! (nft-mint? $CONTRACTNAME (+ last-nft-id u$ID) '$ADDRESS))\n";
    row += "      (map-set token-count '$ADDRESS (+ (get-balance '$ADDRESS) u1))\n";
    for (let i = 0; i < airdrop.length; i++) {
      const element = airdrop[i];
      const airdropRow = row
        .replace('$ID', `${i}`)
        .replaceAll('$ADDRESS', element)
        .replace('$CONTRACTNAME', contractName);
      rows += airdropRow;
    }
    func = func.replace('$DROPS', rows);
    func = func.replace('$IDREACHED', airdrop.length.toString());
    raw += func;
  } else {
    raw = raw.replace('$LASTID', '1');
  }

  let alts = '';
  let hasAlt = false;
  for (let i = 0; i < fungibleOptions.length; i++) {
    if (!fungibleSelected[i]['selected']) {
      continue;
    }
    hasAlt = true;
    const fungibleOption = fungibleOptions[i];
    let alt: string = fungibleOption['symbol'];
    alt = alt.toLowerCase();
    let component = altDefault;
    if (mintpass && mintpass.length > 0) {
      component = altMintpass;
    }

    if (claimOptions != null && claimOptions.length > 0) {
      let options: string = '';
      for (let i = 0; i < claimOptions.length; i++) {
        if (claimOptions[i] === 1) {
          continue;
        }
        const option: string = claimOptions[i].toString();
        const listText: string = ' true';
        options += `
(define-public (claim-${claimOptionsMap[option]}-${alt}) (mint-${alt} (list${listText.repeat(
          claimOptions[i],
        )})))
`;
      }
      component = component.replaceAll('$CLAIMOPTSALT', options);
    } else {
      component = component.replaceAll('$CLAIMOPTSALT', '');
    }

    let altTransferArtist = `(try! (contract-call? '${fungibleOption['principal']} transfer total-artist tx-sender (var-get artist-address) (some 0x00)))`;
    let altTransferComm = `(try! (contract-call? '${fungibleOption['principal']} transfer total-commission tx-sender COMM-ADDR (some 0x00)))`;
    if (!network.isMainnet()) {
      altTransferArtist = '(try! (stx-transfer? total-artist tx-sender (var-get artist-address)))';
      altTransferComm = '(try! (stx-transfer? total-commission tx-sender COMM-ADDR))';
    }

    component = component.replaceAll('$ALTTRANSFERARTIST', altTransferArtist);
    component = component.replaceAll('$ALTTRANSFERCOMM', altTransferComm);
    component = component.replaceAll('$PRICEALT', fungibleSelected[i]['price']);
    component = component.replaceAll('$ALT', alt);
    alts += component;
  }

  if (hasAlt) {
    raw = raw.replaceAll('$ALTS', alts);
  } else {
    raw = raw.replaceAll('$ALTS', '');
  }

  let nonCustLet = letNonCust;
  raw = raw.replaceAll('$LETNONCUST', nonCustLet);
  let nonCustMint = mintNonCust;
  raw = raw.replaceAll('$MINTNONCUST', nonCustMint);

  const options: any = {
    contractName: contractName,
    codeBody: raw,
    network: network,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }

      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${txid}?chain=${networkString}`;
      console.log('View transaction in explorer: ', explorerTransactionUrl);
      onFinish(explorerTransactionUrl, '0x' + txid);
    },
    onCancel: onCancel,
    postConditionMode: PostConditionMode.Deny,
  };

  openContractDeploy(options);
}

async function deployContract(
  contractName: string,
  artistAddress: string,
  price: string,
  mintLimit: string,
  metaHash: string,
  mintpass: any,
  airdrop: any,
  claimOptions: Array<number>,
  fts: Array<any>,
  altTokens: Array<any>,
  useReveal: boolean,
  usePause: boolean,
  mintLimitPerUser: string,
  onFinish: any,
  onCancel: any,
) {
  let contract = defaultTemplate;

  let raw = contract.replaceAll('$CONTRACTNAME', contractName);
  raw = raw.replaceAll('$ARTISTADDRESS', artistAddress);
  raw = raw.replaceAll('$PRICE', price);
  raw = raw.replaceAll('$MINTLIMIT', mintLimit);
  raw = raw.replaceAll('$METAHASH', metaHash);
  raw = raw.replaceAll('$COMMADDRESS', commAddress);
  raw = raw.replaceAll('$PAUSED', usePause.toString());
  raw = raw.replaceAll('$MINTCAP', mintLimitPerUser);

  let nftTrait = "(impl-trait 'SP2PABAF9FTAJYNFZH93XENAJ8FVY99RRM50D2JG9.nft-trait.nft-trait)";
  if (!network.isMainnet()) {
    nftTrait = "(impl-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.nft-trait.nft-trait)";
  }
  raw = raw.replaceAll('$NFTTRAIT', nftTrait);

  // set transfer function
  let transfer = transferNonCust;
  transfer = transfer.replaceAll('$CONTRACTNAME', contractName);
  raw = raw.replaceAll('$TRANSFERCOMP', transfer);

  // include non-custodial marketplace functions
  // let extras = nonCustExtras;
  // extras = extras.replaceAll('$CONTRACTNAME', contractName);
  // raw = raw.replaceAll('$NONCUSTEXTRAS', extras);

  let extras = nonCustExtras;
  extras = extras.replaceAll('$CONTRACTNAME', contractName);
  let commissionTrait =
    "(use-trait commission-trait 'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.commission-trait.commission)";
  if (!network.isMainnet()) {
    commissionTrait =
      "(use-trait commission-trait 'ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7.commission-trait.commission)";
  }
  extras = extras.replaceAll('$COMMISSIONTRAIT', commissionTrait);
  raw = raw.replaceAll('$NONCUSTEXTRAS', extras);

  // set default claim functions
  if (claimOptions != null && claimOptions.length > 0) {
    let optionsMap: any = {
      '3': 'three',
      '5': 'five',
      '10': 'ten',
      '25': 'twentyfive',
    };
    let options: string = '';
    for (let i = 0; i < claimOptions.length; i++) {
      const option: string = claimOptions[i].toString();
      const listText: string = ' true';
      options += `
(define-public (claim-${optionsMap[option]}) (mint (list${listText.repeat(claimOptions[i])})))
`;
    }
    raw = raw.replaceAll('$CLAIMOPTS', options);
  } else {
    raw = raw.replaceAll('$CLAIMOPTS', '');
  }

  // include reveal function
  if (useReveal) {
    raw = raw.replaceAll('$REVEALCOMP', reveal);
  } else {
    raw = raw.replaceAll('$REVEALCOMP', '');
  }

  // define mint function
  if (mintpass) {
    raw = raw.replaceAll('$MINTCOMP', mintMintpass);
  } else {
    raw = raw.replaceAll('$MINTCOMP', mintDefault);
  }

  // implement mintpass extras
  if (mintpass) {
    raw = raw.replaceAll('$MINTPASSEXTRAS', mintpassExtras);
  } else {
    raw = raw.replaceAll('$MINTPASSEXTRAS', '');
  }

  if (mintpass) {
    let row = "(map-set mint-passes 'ADDRESS uAMOUNT)\n";
    for (let i = 0; i < mintpass.length; i++) {
      const element = mintpass[i];
      const mintpassRow = row.replace('ADDRESS', element[0]).replace('AMOUNT', element[1]);
      raw += mintpassRow;
    }
  }

  if (airdrop) {
    let func = airdrops;
    let rows = '';
    let row = "      (try! (nft-mint? $CONTRACTNAME (+ last-nft-id u$ID) '$ADDRESS))\n";
    row += "      (map-set token-count '$ADDRESS (+ (get-balance '$ADDRESS) u1))\n";
    for (let i = 0; i < airdrop.length; i++) {
      const element = airdrop[i];
      const airdropRow = row
        .replace('$ID', `${i}`)
        .replaceAll('$ADDRESS', element)
        .replace('$CONTRACTNAME', contractName);
      rows += airdropRow;
    }
    func = func.replace('$DROPS', rows);
    func = func.replace('$IDREACHED', airdrop.length.toString());
    raw += func;
  } else {
    raw = raw.replace('$LASTID', '1');
  }

  let alts = '';
  let hasAlt = false;
  for (let i = 0; i < fts.length; i++) {
    if (altTokens[i]['price'] == 0) {
      continue;
    }
    hasAlt = true;
    const ft = fts[i];
    let alt: string = ft['symbol'];
    alt = alt.toLowerCase();
    let component = altDefault;
    if (mintpass) {
      component = altMintpass;
    }

    if (claimOptions != null && claimOptions.length > 0) {
      let optionsMap: any = {
        '3': 'three',
        '5': 'five',
        '10': 'ten',
        '25': 'twentyfive',
      };
      let options: string = '';
      for (let i = 0; i < claimOptions.length; i++) {
        const option: string = claimOptions[i].toString();
        const listText: string = ' true';
        options += `
(define-public (claim-${optionsMap[option]}-${alt}) (mint-${alt} (list${listText.repeat(
          claimOptions[i],
        )})))
`;
      }
      component = component.replaceAll('$CLAIMOPTSALT', options);
    } else {
      component = component.replaceAll('$CLAIMOPTSALT', '');
    }

    let altTransferArtist = `(try! (contract-call? '${ft['principal']} transfer total-artist tx-sender (var-get artist-address) (some 0x00)))`;
    let altTransferComm = `(try! (contract-call? '${ft['principal']} transfer total-commission tx-sender COMM-ADDR (some 0x00)))`;
    if (!network.isMainnet()) {
      altTransferArtist = '(try! (stx-transfer? total-artist tx-sender (var-get artist-address)))';
      altTransferComm = '(try! (stx-transfer? total-commission tx-sender COMM-ADDR))';
    }

    component = component.replaceAll('$ALTTRANSFERARTIST', altTransferArtist);
    component = component.replaceAll('$ALTTRANSFERCOMM', altTransferComm);
    component = component.replaceAll('$PRICEALT', altTokens[i]['price']);
    component = component.replaceAll('$ALT', alt);
    alts += component;
  }

  if (hasAlt) {
    raw = raw.replaceAll('$ALTS', alts);
  } else {
    raw = raw.replaceAll('$ALTS', '');
  }

  let nonCustLet = letNonCust;
  raw = raw.replaceAll('$LETNONCUST', nonCustLet);
  let nonCustMint = mintNonCust;
  raw = raw.replaceAll('$MINTNONCUST', nonCustMint);

  const options: any = {
    contractName: contractName,
    codeBody: raw,
    network: network,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }

      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${txid}?chain=${networkString}`;
      console.log('View transaction in explorer: ', explorerTransactionUrl);
      onFinish(explorerTransactionUrl);
    },
    onCancel: onCancel,
    postConditionMode: PostConditionMode.Deny,
  };

  openContractDeploy(options);
}

function togglePause(contractAddress: string, contractName: string, modalCallback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'toggle-pause',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('toggled pause');

      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled toggling pause');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function setBaseUri(
  contractAddress: string,
  contractName: string,
  baseUri: string,
  modalCallback: any,
) {
  const functionArgs: any[] = [stringAsciiCV(baseUri)];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'set-base-uri',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('metadata frozen');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled freeze metadata');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function revealArtwork(
  contractAddress: string,
  contractName: string,
  baseUri: string,
  callback: any,
  isManageScreen?: boolean,
) {
  const functionArgs: any[] = [stringAsciiCV(baseUri)];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'reveal-artwork',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('artwork revealed');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      if (isManageScreen) {
        callback('Success!');
      } else {
        callback({
          success: true,
          successTransaction: txid,
        });
      }
    },
    onCancel: () => {
      console.log('cancelled artwork reveal');
      if (isManageScreen) {
        callback('Transaction cancelled');
      } else {
        callback({
          success: false,
          errorMessage: 'Transaction Cancelled',
        });
      }
    },
  };

  openContractCall(options);
}

function callFreezeMetadata(contractAddress: string, contractName: string, modalCallback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'freeze-metadata',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('metadata frozen');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled freeze metadata');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function callAirdrop(contractAddress: string, contractName: string, modalCallback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'admin-airdrop',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('tokens airdropped');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled airdrop');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function setMintPrice(
  contractAddress: string,
  contractName: string,
  price: number,
  modalCallback: any,
) {
  const functionArgs: any[] = [uintCV(price)];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'set-price',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('mint price set');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled setting mint price');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function setUsdaPrice(
  contractAddress: string,
  contractName: string,
  price: number,
  modalCallback: any,
) {
  console.log(price);

  const functionArgs: any[] = [uintCV(price)];

  console.log(network.isMainnet);

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'set-price-usda',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('mint price set');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled setting mint price');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function setAltMintPrice(
  contractAddress: string,
  contractName: string,
  price: number,
  symbol: string,
  modalCallback: any,
) {
  const functionArgs: any[] = [uintCV(price)];
  const functionName = 'set-price-' + symbol.toLowerCase();

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: functionName,
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('mint price set');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled setting mint price');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function setMintLimit(
  contractAddress: string,
  contractName: string,
  limit: number,
  modalCallback: any,
) {
  const functionArgs: any[] = [uintCV(limit)];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'set-mint-limit',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('mint limit set');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled setting mint limit');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

// Blockify Reinvention mint function
export function mint(
  contractAddress: string,
  contractName: string,
  buyerAddress: string,
  price: number,
  hash: string,
  params: string,
  nftContractAddress: string,
  nftContractName: string,
  nftTokenId: number,
  onFinish: any,
  onCancel: any,
) {
  const functionArgs: any[] = [
    stringAsciiCV(hash),
    stringAsciiCV(params),
    contractPrincipalCV(nftContractAddress, nftContractName),
    uintCV(nftTokenId),
  ];

  const standardFungiblePostCondition = makeStandardSTXPostCondition(
    buyerAddress,
    FungibleConditionCode.LessEqual,
    price,
  );

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'mint',
    network: network,
    functionArgs,
    postConditions: [standardFungiblePostCondition],
    postConditionMode: PostConditionMode.Deny,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('claim finished');
      onFinish();
    },
    onCancel: () => {
      console.log('claim cancelled');
      onCancel();
    },
  };

  openContractCall(options);
}

function claim(
  contractAddress: string,
  contractName: string,
  salePrice: number,
  claimNumber: number,
  symbol: string,
  buyerAddress: string,
  modalCallback: any,
  functionName: string,
  fts: Array<any>,
) {
  const functionArgs: any[] = [];

  let standardFungiblePostCondition;
  if (symbol == 'STX') {
    standardFungiblePostCondition = makeStandardSTXPostCondition(
      buyerAddress,
      FungibleConditionCode.LessEqual,
      salePrice,
    );
  } else {
    let currencies: Array<object> = fts;
    const isAlt = (element: any) => element['symbol'] == symbol;
    const altIndex = currencies.findIndex(isAlt);

    if (altIndex != -1) {
      const currency: any = currencies[altIndex];
      const principal: string = currency['principal'];
      const name: string = currency['assetName'];
      const fungibleAssetInfo = createAssetInfo(
        principal.split('.')[0],
        principal.split('.')[1],
        name,
      );

      standardFungiblePostCondition = makeStandardFungiblePostCondition(
        buyerAddress,
        FungibleConditionCode.LessEqual,
        salePrice,
        fungibleAssetInfo,
      );
    }
  }

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: functionName,
    network: network,
    functionArgs,
    postConditions: [standardFungiblePostCondition],
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('claim finished');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    // onCancel: () => {
    //   console.log("claim cancelled");
    //   modalCallback({
    //     success: false,
    //     errorMessage: "Transaction Cancelled",
    //   });
    // },
  };

  openContractCall(options);
}

function mintBomber(
  contractAddress: string,
  contractName: string,
  salePrice: number,
  size: string,
  buyerAddress: string,
  modalCallback: any,
) {
  let arg = 0;
  if (size === 'XS') {
    arg = 1;
  } else if (size === 'S') {
    arg = 2;
  } else if (size === 'M') {
    arg = 3;
  } else if (size === 'L') {
    arg = 4;
  } else if (size === 'XL') {
    arg = 5;
  } else if (size === '2XL') {
    arg = 6;
  } else if (size === '3XL') {
    arg = 7;
  }

  const functionArgs: any[] = [uintCV(arg)];

  let standardFungiblePostCondition;
  standardFungiblePostCondition = makeStandardSTXPostCondition(
    buyerAddress,
    FungibleConditionCode.LessEqual,
    salePrice,
  );

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'mint',
    network: network,
    functionArgs,
    postConditions: [standardFungiblePostCondition],
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('claim finished');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    // onCancel: () => {
    //   console.log("claim cancelled");
    //   modalCallback({
    //     success: false,
    //     errorMessage: "Transaction Cancelled",
    //   });
    // },
  };

  console.log(options);

  openContractCall(options);
}

function selfMint(
  contractAddress: string,
  contractName: string,
  uris: string[],
  onFinish: any,
  onCancel: any,
) {
  const list = [];
  for (let i = 0; i < uris.length; i++) {
    const uri = uris[i];
    const uriCv = stringAsciiCV(uri);
    list.push(uriCv);
  }
  const listCv = listCV(list);

  const functionArgs: any[] = [listCv];

  // let standardFungiblePostCondition;
  // if (symbol == "STX") {
  //   standardFungiblePostCondition = makeStandardSTXPostCondition(
  //     buyerAddress,
  //     FungibleConditionCode.LessEqual,
  //     new BigNum(salePrice)
  //   );
  // } else {
  //   let currencies: Array<object> = fts;
  //   const isAlt = (element: any) => element["symbol"] == symbol;
  //   const altIndex = currencies.findIndex(isAlt);
  //   if (altIndex != -1) {
  //     const currency: any = currencies[altIndex];
  //     const principal: string = currency["principal"];
  //     const name: string = currency["assetName"];
  //     const fungibleAssetInfo = createAssetInfo(
  //       principal.split(".")[0],
  //       principal.split(".")[1],
  //       name
  //     );

  //     standardFungiblePostCondition = makeStandardFungiblePostCondition(
  //       buyerAddress,
  //       FungibleConditionCode.LessEqual,
  //       salePrice,
  //       fungibleAssetInfo
  //     );
  //   }
  // }

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'claim',
    network: network,
    functionArgs,
    postConditions: [],
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }

      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${txid}?chain=${networkString}`;
      console.log('View transaction in explorer: ', explorerTransactionUrl);
      onFinish(explorerTransactionUrl);
    },
    onCancel: onCancel,
    postConditionMode: PostConditionMode.Deny,
  };

  openContractCall(options);
}

function setTokenBaseUri(
  contractAddress: string,
  contractName: string,
  hash: string,
  tokenId: string,
  functionName: string,
  onFinish: any,
  onCancel: any,
) {
  const hashCv = stringAsciiCV(hash);
  const id = parseInt(tokenId);
  const idCv = uintCV(id);

  const functionArgs: any[] = [hashCv, idCv];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName,
    network: network,
    functionArgs,
    postConditions: [],
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }

      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${txid}?chain=${networkString}`;
      console.log('View transaction in explorer: ', explorerTransactionUrl);
      onFinish(explorerTransactionUrl);
    },
    onCancel: onCancel,
    postConditionMode: PostConditionMode.Deny,
  };

  openContractCall(options);
}

function ftClaim(
  buyerAddress: string,
  claimTraitAddress: string,
  claimTraitName: string,
  currency: string,
  ftPrice: number,
  minDy: number,
  modalCallback: any,
) {
  let ftTraitAddress = 'SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9';
  let ftTraitName = 'token-wbtc';
  let assetInfo = createAssetInfo(
    'SP3DX3H4FEYZJZ586MFBS25ZW3HZDMEW92260R2PR',
    'Wrapped-Bitcoin',
    'wrapped-bitcoin',
  );

  // account for other alex currencies
  // if (currency == "xBTC") {
  // ftTraitAddress = "SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9";
  // ftTraitName = "token-wbtc";
  // assetInfo = createAssetInfo(
  //   "SP3DX3H4FEYZJZ586MFBS25ZW3HZDMEW92260R2PR",
  //   "Wrapped-Bitcoin",
  //   "wrapped-bitcoin"
  // );
  // }

  const functionArgs: any[] = [
    contractPrincipalCV(claimTraitAddress, claimTraitName),
    contractPrincipalCV(ftTraitAddress, ftTraitName),
    uintCV(ftPrice),
    uintCV(minDy),
  ];
  const contractAddress = 'SP2BE8TZATXEVPGZ8HAFZYE5GKZ02X0YDKAN7ZTGW';
  const contractName = 'ft-claim-test';

  const contractSTXPostCondition = makeContractSTXPostCondition(
    'SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9',
    'alex-vault',
    FungibleConditionCode.GreaterEqual,
    minDy / 100,
  );

  const standardFungiblePostCondition = makeStandardFungiblePostCondition(
    buyerAddress,
    FungibleConditionCode.LessEqual,
    ftPrice,
    assetInfo,
  );

  const standardSTXPostCondition = makeStandardSTXPostCondition(
    buyerAddress,
    FungibleConditionCode.LessEqual,
    minDy / 100,
  );

  const options: any = {
    contractAddress,
    contractName,
    functionName: 'ft-mint',
    network: network,
    functionArgs,
    postConditionMode: PostConditionMode.Deny,
    postConditions: [
      contractSTXPostCondition,
      standardFungiblePostCondition,
      standardSTXPostCondition,
    ],
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('claim finished');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('claim cancelled');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function initMintpass(contractAddress: string, contractName: string, modalCallback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'enable-premint',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('mintpass sale enabled');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('init mintpass cancelled');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function initPublicSale(contractAddress: string, contractName: string, modalCallback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'toggle-sale-state',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    onFinish: (data: any) => {
      console.log('public sale enabled');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('public sale cancelled');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function getMintpassEnabled(contractAddress: string, contractName: string, callback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'get-premint-enabled',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => {
      const enabled = cvToJSON(res).value.value;
      console.log('mintpass enabled: ' + enabled);
      callback(enabled);
    })
    .catch((err) => console.log(err));
}

function getPublicSaleEnabled(contractAddress: string, contractName: string, callback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'get-sale-enabled',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => {
      const enabled = cvToJSON(res).value.value;
      callback(enabled);
    })
    .catch((err) => console.log(err));
}

function getMintpasses(
  contractAddress: string,
  contractName: string,
  buyerAddress: string,
  callback: any,
) {
  const functionArgs: any[] = [standardPrincipalCV(buyerAddress)];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'get-passes',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => {
      const passes = cvToJSON(res).value;
      callback(passes);
    })
    .catch((err) => console.log(err));
}

function getPrice(contractAddress: string, contractName: string, callback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'get-price',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => {
      const price = cvToJSON(res).value.value;
      callback(price);
    })
    .catch((err) => console.log(err));
}

function getAltPrice(contractAddress: string, contractName: string, symbol: string, callback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: `get-price-${symbol}`,
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => {
      const price = cvToJSON(res).value.value;
      callback({ symbol: symbol.toUpperCase(), price: price });
    })
    .catch((err) => console.log(err));
}

function getMintLimit(contractAddress: string, contractName: string, callback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'get-mint-limit',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => {
      const limit = cvToJSON(res).value.value;
      callback(limit);
    })
    .catch((err) => console.log(err));
}

function getMintPermitted(
  contractAddress: string,
  contractName: string,
  buyerAddress: string,
  callback: any,
) {
  const functionArgs: any[] = [standardPrincipalCV(buyerAddress)];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'mint-permitted',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => {
      const permitted = cvToJSON(res).value;
      callback(permitted);
    })
    .catch((err) => console.log(err));
}

function getPaused(contractAddress: string, contractName: string, callback: any) {
  const functionArgs: any[] = [];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'get-paused',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => {
      const paused = cvToJSON(res).value.value;
      callback(paused);
    })
    .catch((err) => console.log(err));
}

//Minimum Slippage Amount will be in STX and is determined by app
//Remember that native STX is 6 decimals, unlike WSTX, which is 8
//We use wstx (1e8) as a parameter for the funtion call and use
// native STX (1e6) in the post condition
const MINIMUM_STX_SLIPPAGE_AMOUNT = 0.0001e6; // example .0001 STX
const ALEX_DEPLOYER = 'SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9'; //fixed
const FIXED_WEIGHT_POOL = 'fixed-weight-pool-v1-01';
const SWAPPING_FUNCTION = 'swap-helper';
const ALEX_VAULT = 'alex-vault';
const poolWeight = 0.5e8;
const MAINNET_NODE_URL = 'https://stacks-node-api.alexlab.co';
const EXCHANGE_RATE_FUNCTION = 'get-helper';

export async function getSTXInBTC(stxAmount: number): Promise<any> {
  const options = {
    network: new StacksMainnet({
      url: MAINNET_NODE_URL,
    }),
    contractAddress: ALEX_DEPLOYER,
    contractName: FIXED_WEIGHT_POOL,
    functionName: EXCHANGE_RATE_FUNCTION,
    functionArgs: [
      // contractPrincipalCV(ALEX_DEPLOYER, "token-wstx"),
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wbtc'),
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wstx'),
      uintCV(poolWeight),
      uintCV(poolWeight),
      uintCV(1 * 1e8), //<stacks amount>
    ],
    senderAddress: ALEX_DEPLOYER,
  };
  const result = await callReadOnlyFunction({
    ...options,
  });

  if (result.type === ClarityType.ResponseOk && result.value.type === ClarityType.UInt) {
    return (1 / (Number(result.value.value) / 1e8)) * stxAmount;
  }
  return undefined;
}

export async function getSTXInBAN(stxAmount: number): Promise<any> {
  const options = {
    network: new StacksMainnet({
      url: MAINNET_NODE_URL,
    }),
    contractAddress: ALEX_DEPLOYER,
    contractName: 'swap-helper-v1-03',
    functionName: EXCHANGE_RATE_FUNCTION,
    functionArgs: [
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wban'),
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wstx'),
      uintCV(poolWeight),
    ],
    senderAddress: ALEX_DEPLOYER,
  };
  const result = await callReadOnlyFunction({
    ...options,
  });

  if (result.type === ClarityType.ResponseOk && result.value.type === ClarityType.UInt) {
    return (1 / (Number(result.value.value) / 1e8)) * stxAmount;
  }
  return undefined;
}

export async function getUSDInBAN(stxAmount: number): Promise<any> {
  const options = {
    network: new StacksMainnet({
      url: MAINNET_NODE_URL,
    }),
    contractAddress: ALEX_DEPLOYER,
    contractName: 'swap-helper-v1-03',
    functionName: EXCHANGE_RATE_FUNCTION,
    functionArgs: [
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wban'),
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wusda'),
      uintCV(poolWeight),
    ],
    senderAddress: ALEX_DEPLOYER,
  };
  const result = await callReadOnlyFunction({
    ...options,
  });

  if (result.type === ClarityType.ResponseOk && result.value.type === ClarityType.UInt) {
    return (1 / (Number(result.value.value) / 1e8)) * stxAmount;
  }
  return undefined;
}

export async function getSTXInMIA(stxAmount: number): Promise<any> {
  const options = {
    network: new StacksMainnet({
      url: MAINNET_NODE_URL,
    }),
    contractAddress: ALEX_DEPLOYER,
    contractName: 'swap-helper-v1-03',
    functionName: EXCHANGE_RATE_FUNCTION,
    functionArgs: [
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wmia'),
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wstx'),
      uintCV(poolWeight),
    ],
    senderAddress: ALEX_DEPLOYER,
  };
  const result = await callReadOnlyFunction({
    ...options,
  });

  if (result.type === ClarityType.ResponseOk && result.value.type === ClarityType.UInt) {
    return (1 / (Number(result.value.value) / 1e8)) * stxAmount;
  }
  return undefined;
}

export async function getSTXInSLIME(stxAmount: number): Promise<any> {
  const options = {
    network: new StacksMainnet({
      url: MAINNET_NODE_URL,
    }),
    contractAddress: ALEX_DEPLOYER,
    contractName: 'swap-helper-v1-03',
    functionName: EXCHANGE_RATE_FUNCTION,
    functionArgs: [
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wslm'),
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wstx'),
      uintCV(poolWeight),
    ],
    senderAddress: ALEX_DEPLOYER,
  };
  const result = await callReadOnlyFunction({
    ...options,
  });

  if (result.type === ClarityType.ResponseOk && result.value.type === ClarityType.UInt) {
    return (1 / (Number(result.value.value) / 1e8)) * stxAmount;
  }
  return undefined;
}

export async function getUSDInSLIME(stxAmount: number): Promise<any> {
  const options = {
    network: new StacksMainnet({
      url: MAINNET_NODE_URL,
    }),
    contractAddress: ALEX_DEPLOYER,
    contractName: 'swap-helper-v1-03',
    functionName: EXCHANGE_RATE_FUNCTION,
    functionArgs: [
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wslm'),
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wusda'),
      uintCV(poolWeight),
    ],
    senderAddress: ALEX_DEPLOYER,
  };
  const result = await callReadOnlyFunction({
    ...options,
  });

  console.log(result);

  if (result.type === ClarityType.ResponseOk && result.value.type === ClarityType.UInt) {
    return (1 / (Number(result.value.value) / 1e8)) * stxAmount;
  }
  return undefined;
}

export async function getSTXInNYC(stxAmount: number): Promise<any> {
  const options = {
    network: new StacksMainnet({
      url: MAINNET_NODE_URL,
    }),
    contractAddress: ALEX_DEPLOYER,
    contractName: 'swap-helper-v1-03',
    functionName: EXCHANGE_RATE_FUNCTION,
    functionArgs: [
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wnycc'),
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wstx'),
      uintCV(poolWeight),
    ],
    senderAddress: ALEX_DEPLOYER,
  };
  const result = await callReadOnlyFunction({
    ...options,
  });

  if (result.type === ClarityType.ResponseOk && result.value.type === ClarityType.UInt) {
    return (1 / (Number(result.value.value) / 1e8)) * stxAmount;
  }
  return undefined;
}

export async function getSTXInMEGA(stxAmount: number): Promise<any> {
  const options = {
    network: new StacksMainnet({
      url: MAINNET_NODE_URL,
    }),
    contractAddress: ALEX_DEPLOYER,
    contractName: 'swap-helper-v1-03',
    functionName: EXCHANGE_RATE_FUNCTION,
    functionArgs: [
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wmia'),
      contractPrincipalCV(ALEX_DEPLOYER, 'token-wstx'),
      uintCV(poolWeight),
    ],
    senderAddress: ALEX_DEPLOYER,
  };
  const result = await callReadOnlyFunction({
    ...options,
  });

  console.log(result);

  if (result.type === ClarityType.ResponseOk && result.value.type === ClarityType.UInt) {
    return (1 / (Number(result.value.value) / 1e8)) * stxAmount;
  }
  return undefined;
}

const getStandardFungiblePC = (senderAddress: string, amount: number) => {
  const code = FungibleConditionCode.Equal;
  const assetAddress = 'SP3DX3H4FEYZJZ586MFBS25ZW3HZDMEW92260R2PR';
  const assetContractName = 'Wrapped-Bitcoin';
  const assetName = 'wrapped-bitcoin';
  const fungibleAssetInfo = createAssetInfo(assetAddress, assetContractName, assetName);
  return makeStandardFungiblePostCondition(
    senderAddress,
    code,
    Math.round(amount * 1e8),
    fungibleAssetInfo,
  );
};

const getContractSTXPC = (amount: number) => {
  const code = FungibleConditionCode.GreaterEqual;
  const contractAddress = ALEX_DEPLOYER;
  const contractName = ALEX_VAULT;

  return makeContractSTXPostCondition(contractAddress, contractName, code, amount);
};

const generateBTCtoSTXPostConditions = (senderAddress: string, btcAmount: number) => {
  return [
    getStandardFungiblePC(senderAddress, btcAmount),
    getContractSTXPC(MINIMUM_STX_SLIPPAGE_AMOUNT),
  ];
};

export const swapBTCforSTX = (senderAddress: string, btcAmount: number): Promise<string> => {
  return new Promise((res, rej) => {
    const mainnet = new StacksMainnet({
      url: MAINNET_NODE_URL,
    });
    openContractCall({
      network: mainnet,
      contractAddress: ALEX_DEPLOYER,
      contractName: FIXED_WEIGHT_POOL,
      functionName: SWAPPING_FUNCTION,
      functionArgs: [
        contractPrincipalCV(ALEX_DEPLOYER, 'token-wbtc'),
        contractPrincipalCV(ALEX_DEPLOYER, 'token-wstx'),
        uintCV(poolWeight),
        uintCV(poolWeight),
        uintCV(btcAmount * 1e8),
        someCV(uintCV(MINIMUM_STX_SLIPPAGE_AMOUNT)),
      ],
      anchorMode: AnchorMode.Any,
      postConditionMode: PostConditionMode.Deny,
      postConditions: generateBTCtoSTXPostConditions(senderAddress, btcAmount),
      onFinish: (result) => {
        console.log(result);
        res(result.txId);
      },
      onCancel: () => {
        // rej(new Error("Transaction canceled"));
        console.log('transaction cancelled');
      },
    });
  });
};

function listAuction(
  sellerAddress: string,
  contractAddress: string,
  contractName: string,
  tokenId: number,
  blockLength: number,
  reservePrice: number,
  auctionType: number,
  modalCallback: any,
) {
  const functionArgs: any[] = [
    contractPrincipalCV(contractAddress, contractName),
    uintCV(tokenId),
    tupleCV({
      'block-length': uintCV(blockLength),
      'reserve-price': uintCV(reservePrice),
      type: uintCV(auctionType),
    }),
  ];

  const assetInfo = createAssetInfo(contractAddress, contractName, contractName);

  // const contractNonFungiblePostCondition = makeContractNonFungiblePostCondition(
  //   auctionContractAddress,
  //   auctionContractName,
  //   NonFungibleConditionCode.Owns,
  //   assetInfo,
  //   stringAsciiCV(contractName)
  // );

  const standardNonFungiblePostCondition = makeStandardNonFungiblePostCondition(
    sellerAddress,
    NonFungibleConditionCode.DoesNotOwn,
    assetInfo,
    uintCV(tokenId),
  );

  const options: any = {
    contractAddress: auctionContractAddress,
    contractName: auctionContractName,
    functionName: 'list-auction',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    postConditionMode: PostConditionMode.Deny,
    postConditions: [standardNonFungiblePostCondition],
    onFinish: (data: any) => {
      console.log('auction listed');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled list auction');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function endAuction(
  sellerAddress: string,
  contractAddress: string,
  contractName: string,
  tokenId: number,
  modalCallback: any,
  // highBidder?: string,
  highBid: number,
) {
  const functionArgs: any[] = [contractPrincipalCV(contractAddress, contractName), uintCV(tokenId)];

  const assetInfo = createAssetInfo(contractAddress, contractName, contractName);

  const contractNonFungiblePostCondition = makeContractNonFungiblePostCondition(
    auctionContractAddress,
    auctionContractName,
    NonFungibleConditionCode.DoesNotOwn,
    assetInfo,
    uintCV(tokenId),
  );

  const contractSTXPostCondition = makeContractSTXPostCondition(
    auctionContractAddress,
    auctionContractName,
    FungibleConditionCode.LessEqual,
    highBid * 1e6,
  );

  // const standardNonFungiblePostCondition = makeStandardNonFungiblePostCondition(
  //   sellerAddress,
  //   NonFungibleConditionCode.DoesNotOwn,
  //   assetInfo,
  //   uintCV(tokenId)
  // );

  const options: any = {
    contractAddress: auctionContractAddress,
    contractName: auctionContractName,
    functionName: 'end-auction',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    postConditionMode: PostConditionMode.Deny,
    postConditions: [contractNonFungiblePostCondition, contractSTXPostCondition],
    onFinish: (data: any) => {
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

function unlistAuction(
  contractAddress: string,
  contractName: string,
  tokenId: number,
  modalCallback: any,
) {
  const functionArgs: any[] = [contractPrincipalCV(contractAddress, contractName), uintCV(tokenId)];

  const assetInfo = createAssetInfo(contractAddress, contractName, contractName);

  const contractNonFungiblePostCondition = makeContractNonFungiblePostCondition(
    auctionContractAddress,
    auctionContractName,
    NonFungibleConditionCode.DoesNotOwn,
    assetInfo,
    uintCV(tokenId),
  );

  const options: any = {
    contractAddress: auctionContractAddress,
    contractName: auctionContractName,
    functionName: 'unlist-auction',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    postConditionMode: PostConditionMode.Deny,
    postConditions: [contractNonFungiblePostCondition],
    onFinish: (data: any) => {
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
      });
    },
    onCancel: () => {
      console.log('cancelled end auction');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

async function getAuction(contractAddress: string, contractName: string, tokenId: number) {
  const functionArgs: any[] = [contractPrincipalCV(contractAddress, contractName), uintCV(tokenId)];

  const options: any = {
    contractAddress: auctionContractAddress,
    contractName: auctionContractName,
    functionName: 'get-auction',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  const res = await callReadOnlyFunction(options);
  const json = cvToJSON(res);
  if (json.success) {
    const auction = json.value.value;
    return auction;
  } else {
    return null;
  }
}

async function getAuctionBid(contractAddress: string, contractName: string, tokenId: number) {
  const functionArgs: any[] = [contractPrincipalCV(contractAddress, contractName), uintCV(tokenId)];

  const options: any = {
    contractAddress: auctionContractAddress,
    contractName: auctionContractName,
    functionName: 'get-auction-bid',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  const res = await callReadOnlyFunction(options);
  const json = cvToJSON(res);
  if (json) {
    const bid = cvToJSON(res).value;
    return bid;
  } else {
    return null;
  }
}

function placeBid(
  buyerAddress: string,
  contractAddress: string,
  contractName: string,
  tokenId: number,
  bidAmount: number,
  modalCallback: any,
  highBidder?: string,
  highBid?: number,
) {
  const functionArgs: any[] = [
    contractPrincipalCV(contractAddress, contractName),
    uintCV(tokenId),
    uintCV(bidAmount),
  ];

  const standardSTXPostCondition = makeStandardSTXPostCondition(
    buyerAddress,
    FungibleConditionCode.LessEqual,
    bidAmount,
  );
  let postConditions = [standardSTXPostCondition];

  const currentHighBid = (highBid || 0) * 1e6;

  // if (highBid && highBidder && highBid > 0) {
  //   const contractSTXPostCondition = makeContractSTXPostCondition(
  //     auctionContractAddress,
  //     auctionContractName,
  //     FungibleConditionCode.GreaterEqual,
  //     highBid * 1e6
  //   );
  //   postConditions.push(contractSTXPostCondition);
  // }

  const contractSTXPostCondition = makeContractSTXPostCondition(
    auctionContractAddress,
    auctionContractName,
    FungibleConditionCode.GreaterEqual,
    currentHighBid,
  );
  postConditions.push(contractSTXPostCondition);

  const options: any = {
    contractAddress: auctionContractAddress,
    contractName: auctionContractName,
    functionName: 'place-bid',
    network: network,
    functionArgs,
    appDetails: {
      name: 'Gamma.io',
    },
    postConditionMode: PostConditionMode.Deny,
    postConditions: postConditions,
    onFinish: (data: any) => {
      console.log('bid placed');
      let txid = '';
      if (typeof data.txId === 'string' || data.txId instanceof String) {
        txid = data.txId;
      } else {
        txid = data.txId.txid;
      }
      modalCallback({
        success: true,
        successTransaction: txid,
        fetchMempool: true,
      });
    },
    onCancel: () => {
      console.log('cancelled place bid');
      modalCallback({
        success: false,
        errorMessage: 'Transaction Cancelled',
      });
    },
  };

  openContractCall(options);
}

async function getOwner(contractAddress: string, contractName: string, tokenId: number) {
  const functionArgs: any[] = [uintCV(tokenId)];

  const options: any = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: 'get-owner',
    network: network,
    functionArgs,
    senderAddress: contractAddress,
  };

  const res = await callReadOnlyFunction(options);
  const json = cvToJSON(res);
  return json.value.value.value;
}

export function call(
  contractAddress: string,
  contractName: string,
  functionName: string,
  access: string,
  functionArgs?: { value: string; type: string }[],
  onFinish?: any,
  onCancel?: any,
) {
  const fArgs: any[] = [];
  if (functionArgs) {
    for (let i = 0; i < functionArgs.length; i++) {
      const arg = functionArgs[i];

      if (arg.type.includes('optional') && arg.type.includes('string')) {
        arg.value ? fArgs.push(someCV(stringAsciiCV(arg.value))) : fArgs.push(noneCV());
      } else if (arg.type.includes('optional') && arg.type.includes('uint')) {
        arg.value ? fArgs.push(someCV(uintCV(arg.value))) : fArgs.push(noneCV());
      } else if (arg.type === 'string-ascii') {
        fArgs.push(stringAsciiCV(arg.value));
      } else if (arg.type.includes('uint')) {
        if (isNaN(parseInt(arg.value))) {
          onFinish('Value must be an integer');
          return;
        }
        fArgs.push(uintCV(parseInt(arg.value)));
      } else if (arg.type === 'trait_reference') {
        const principalCheck = isValidContractPrincipal(arg.value);
        if (!principalCheck.isValid) {
          console.log(principalCheck.error);
          onFinish(principalCheck.error);
          return;
        }
        fArgs.push(contractPrincipalCV(arg.value.split('.')[0], arg.value.split('.')[1]));
      } else if (arg.type === 'principal') {
        const addressCheck = isValidAddress(arg.value);
        const contractPrincipalCheck = isValidContractPrincipal(arg.value);
        if (addressCheck.isValid) {
          fArgs.push(standardPrincipalCV(arg.value));
        } else if (contractPrincipalCheck.isValid) {
          fArgs.push(contractPrincipalCV(arg.value.split('.')[0], arg.value.split('.')[1]));
        } else if (!addressCheck.isValid && !addressCheck.isValid) {
          onFinish('Address is not a valid principal');
        }
      } else if (functionName === 'mint-for-many' && arg.type === 'list') {
        const values = arg.value.split(',');
        const list = [];
        for (let i = 0; i < values.length; i++) {
          const value = values[i];
          console.log(value);
          const principal = standardPrincipalCV(value);
          list.push(principal);
        }
        fArgs.push(listCV(list));
      } else if (functionName === 'add-mintpasses' && arg.type === 'list') {
        const values = arg.value.split(',').map(function (item) {
          return item.trim();
        });
        const list = [];
        for (let i = 0; i < values.length; i++) {
          const value = values[i];
          console.log(value);
          const principal = standardPrincipalCV(value);
          list.push(principal);
        }
        fArgs.push(listCV(list));
      }
    }
  }

  // const functionArgs: any[] = [
  //   stringAsciiCV(hash),
  //   stringAsciiCV(params),
  //   contractPrincipalCV(nftContractAddress, nftContractName),
  //   uintCV(nftTokenId),
  // ];

  if (access === 'public') {
    const options: any = {
      contractAddress: contractAddress,
      contractName: contractName,
      functionName: functionName,
      network: network,
      functionArgs: fArgs,
      postConditionMode: PostConditionMode.Allow,
      appDetails: {
        name: 'Gamma.io',
      },
      onFinish: (data: any) => {
        let txid = '';
        if (typeof data.txId === 'string' || data.txId instanceof String) {
          txid = data.txId;
        } else {
          txid = data.txId.txid;
        }

        const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${txid}?chain=${networkString}`;
        console.log('View transaction in explorer: ', explorerTransactionUrl);
        onFinish('', explorerTransactionUrl);
      },
      onCancel: () => {
        onCancel();
      },
    };

    openContractCall(options);
  } else {
    const options: any = {
      contractAddress: contractAddress,
      contractName: contractName,
      functionName: functionName,
      network: network,
      functionArgs: fArgs,
      senderAddress: contractAddress,
    };

    callReadOnlyFunction(options)
      .then((res) => {
        const json = cvToJSON(res);

        if (json.value) {
          onFinish(json.value);
        } else if (json.value && json.value.value) {
          onFinish(json.value.value);
        } else {
          onFinish("Couldn't read response.");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

export {
  deployContract,
  deployAuctionContract,
  deployPersonalContract,
  deployPublicContract,
  togglePause,
  setMintPrice,
  setAltMintPrice,
  setMintLimit,
  claim,
  initMintpass,
  initPublicSale,
  getMintpassEnabled,
  getPublicSaleEnabled,
  getMintpasses,
  getPrice,
  getAltPrice,
  getMintLimit,
  pass,
  network,
  isMainnet,
  callAirdrop,
  callFreezeMetadata,
  setBaseUri,
  revealArtwork,
  getPaused,
  ftClaim,
  listAuction,
  getAuction,
  getAuctionBid,
  basePath,
  placeBid,
  getOwner,
  endAuction,
  auctionContractPrincipal,
  auctionContractAddress,
  auctionContractName,
  auctionAddresses,
  selfMint,
  setUsdaPrice,
  deployEditionsContract,
  unlistAuction,
  setTokenBaseUri,
  getMintPermitted,
  mintBomber,
};
